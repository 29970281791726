@import "../../styles/variables";
@import "../../styles/utils";

header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 1em;
  background: linear-gradient(to top left, $primary, darken($primary, 10%));
  color: $secondary;
  box-shadow: lighten($primary, 10%) 0 0 4px 0;
  .title-container {
    flex: 1;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
    padding: 3px;
    align-items: center;
    height: 100%;

    .logo {
      height: 35px;
      padding: 2px;

      a {
        .logo-img {
          height: 100%;
        }
      }
    }
    a {
      .title {
        font-size: 1.1rem;
        color: $text;
        line-height: 1.1rem;
        @include small-screen {
          display: none;
        }
        @include medium-screen {
          display: block;
        }
      }
      .sub-title {
        color: darken($text, 10%);

        @include medium-screen {
          display: block;
        }
        @include small-screen {
          display: none;
        }
      }
    }
  }

  .call-outs {
    flex: 1;
    display: grid;
    place-items: center;
    width: fit-content;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    transition: all 500ms;
    @include mobile-screen {
      font-size: 0.8rem;
    }
    a {
      color: $highlight;
      &:hover,
      &:focus {
        color: lighten($secondary, 10%);
        font-size: 1.6rem;
        @include mobile-screen {
          font-size: 0.9rem;
        }
      }
    }
  }

  #flash {
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .info-container {
    flex: 1;
    width: fit-content;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
    .project-button {
      color: $text;
      position: relative;
      &:hover,
      &:focus {
        color: $secondary;
      }
    }
    a {
      padding: 0 1em;
      color: $text;
      &:hover,
      &:focus {
        color: $secondary;
      }
    }
    @include mobile-screen {
      a {
        display: none;
      }
    }
    @include small-screen {
      a {
        display: none;
      }
    }
  }

  @include mobile-screen {
    .title {
      font-size: 0.9rem;
      color: $secondary;
      display: none;
    }
    .sub-title {
      display: none;
    }
  }
  @include small-screen {
    .title {
      font-size: 0.9rem;
      color: $secondary;
      display: none;
    }
    .sub-title {
      display: none;
    }
  }
}
