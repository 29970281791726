@import "../../styles/variables";
@import "../../styles/utils";

.flex-button {
  width: 100%;
  max-width: fit-content;
  padding: 0.5em 1em;
  background: $primary;
  color: $text;
  border-radius: $border-radius;
  transition: all 700ms;
}

.flex-img-button {
  color: $secondary;
  &:hover {
    color: $primary;
  }
}

.flex-address {
  text-align: center;
  padding: 0.5em;
  font-size: 1rem;
  @include mobile-screen {
    width: 100%;
    font-size: 0.65rem;
    line-break: anywhere;
  }
}

.flex-call-outs {
  display: flex;
  flex-flow: row wrap;
  gap: 1.5em;
  width: 90%;
  max-width: $max-content;
  margin: 1em auto 0;
  .flex-item {
    flex: 1;
    background: $component-background;
    color: darken($component-text, 30%);
    border-radius: $border-radius;
    box-shadow: $component-box-shadow;
    padding: 1em;
    .flex-item-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      h2 {
        color: darken($component-text, 30%);
        text-transform: uppercase;
      }
    }
  }

  @include mobile-screen {
    // width: 100%;
  }
  @include small-screen {
    // width: 100%;
  }

  .flex-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    .flex-stat-item {
      @include mobile-screen {
        font-size: 0.8rem;
      }
      .value {
        margin: 5px;
        color: $secondary;
      }
    }
  }

  @include mobile-screen {
    width: 90%;
    margin: 0 auto;
    .flex-item {
      box-shadow: $component-box-shadow;
    }
  }
}

.about-layout {
  width: 90%;
  max-width: $max-content;
  margin: 15px auto;
  .about-content {
    box-shadow: $component-box-shadow;
    background: $component-background;
    color: darken($component-text, 30%);
    padding: 10px;
    border-radius: 6px;

    .title {
      text-align: center;
      color: $component-text;
      font-size: 1.7rem;
    }

    section {
      margin: 0;
      margin-bottom: 15px;
      padding: 10px;
      div {
        margin-top: 10px;
        &.standout {
          font-size: 1.15rem;
          font-weight: 500;
        }
        a {
          padding: 0 3px;
        }
      }
    }
  }
  @include mobile-screen {
    width: 100%;
    padding: 0;

    .about-content {
      padding: 0;
      .title {
        font-size: 1rem;
      }
      section {
        margin: 0;
        font-size: 0.9rem;
        div {
          &.standout {
            font-size: 0.9rem;
            font-weight: 300;
          }
          a {
            padding: 0;
          }
        }
      }
    }
  }
}
