$pink: #ff48c4;
$red: #ff3f3f;
$blue: #2bd1fc;
$teal: #2ef9ff;
$yellow: #f3ea5f;
$purple: #c04df9;
$orange: #ffc761;
$white: #ffffff;
$grey: #807b7b;
$black: #000000;
$green: #5fff47;
$lime: #b3f26b;

$primary: $pink;
$secondary: $blue;
$highlight: $yellow;

$component-background: $yellow;
$component-text: $purple;
$component-box-shadow: $primary 0 0 2px 3px;
$project-box-shadow: $highlight 0 0 2px 3px;

$punk-bg: #648596;

$text: $white;
$dark-text: $grey;
$border-radius: 7px;

$max-content: 1200px;
