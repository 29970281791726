@import "./styles/variables";
html,
body {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    width: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary;
  }
}
