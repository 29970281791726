@import "../../styles/variables";
@import "../../styles/utils";
.center-scoreboard {
  max-width: $max-content;
  margin: 0 auto 2em;
  padding: 1em;
  display: flex;
  flex-flow: column wrap;
  gap: 0.5em;

  .tweet-modal {
    position: fixed;

    left: -100%;

    background: rgba(0, 0, 0, 0.5);
    transition: all ease-in-out 500ms;
    width: 0;

    z-index: 100;

    &.show-modal {
      width: 100%;
      display: grid;
      place-items: center;
      transition: all ease-in-out 500ms;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    &.no-modal {
      display: grid;
      place-items: center;
      transition: all ease-in-out 500ms;
      left: 0;
      top: -200%;
      bottom: 0;
      right: 0;
    }
    .preview {
      border-radius: $border-radius;
      border: 3px solid $primary;
      padding: 1em;
      z-index: 100;
      background: $secondary;
      font-weight: bold;
      h2 {
        text-transform: uppercase;
        color: $highlight;
      }

      p {
        font-size: 1.1rem;
        color: $component-text;
        margin: 1em auto;
        max-width: 45ch;
        a {
          color: $primary;
          padding: 0 1em;
          text-transform: uppercase;
        }
      }
      .primary-button {
        background: $primary;
        padding: 0.5em 1em;
        width: fit-content;
        font-weight: bolder;
        border-radius: $border-radius;
        transition: all ease-in 100ms;
        color: $text;
        position: relative;
        cursor: pointer;
        margin: 1em;
        &:hover,
        &:active,
        &:focus {
          background: darken($primary, 10%);
        }
      }
      .image-container {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        aspect-ratio: 1/1;
        img {
          border-radius: $border-radius;
          aspect-ratio: 1/1;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes move_wave {
    0% {
      transform: translateX(0) translateZ(0) scaleY(1);
    }
    50% {
      transform: translateX(-25%) translateZ(0) scaleY(0.55);
    }
    100% {
      transform: translateX(-50%) translateZ(0) scaleY(1);
    }
  }
  div & div {
    margin-top: 1em;
  }
  .action-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 1em;
    flex-grow: 1;
    margin-top: 0;

    .address {
      font-weight: bolder;
      color: $text;
      background: $secondary;
      padding: 0.5em 1em;
      border-radius: $border-radius;
      text-transform: uppercase;
      max-width: fit-content;
      @media (max-width: 800px) {
        font-size: 0.8rem;
      }
    }

    .screen-grab-button {
      background: $secondary;
      padding: 0.5em 1em;
      width: fit-content;
      font-weight: bolder;
      border-radius: $border-radius;
      transition: all ease-in 100ms;
      color: $text;
      position: relative;
      cursor: pointer;
      margin: 0;
      &:hover,
      &:active,
      &:focus {
        background: darken($secondary, 10%);
      }
      .meta-mask-logo {
        width: 20px;
        background: none;
        margin-left: 1em;
      }
      .extra-menu {
        background: none;
        display: flex;
        flex-flow: column nowrap;
        gap: 0.3em;
        position: absolute;
        right: 0;
        top: 3em;
        border-radius: $border-radius;
        list-style-type: none;
        padding: 0;
        margin: 0;
        transition: all ease-in 100ms;
        .extra-menu-item {
          background: $secondary;
          padding: 1em;

          border-radius: $border-radius;

          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 1em;
        }
        :hover,
        :active,
        :focus {
          background: darken($secondary, 5%);
        }
      }
    }
    .twitter-icon {
      color: $highlight;
    }
  }

  .score-board-container {
    color: $text;
    background: $component-background;
    padding: 1em;
    border-radius: $border-radius;
    box-shadow: $component-box-shadow;
    min-height: 500px;

    .landing-content {
      font-size: 1.3rem;
      color: $component-text;
      text-align: center;
      h1 {
        color: $primary;
        text-transform: uppercase;
      }
    }

    .degen-plot-chart {
      background: transparent;
      width: 100%;
    }
    .loading-container {
      width: 100%;
      min-height: 500px;
      padding: 0;
      margin: 0;
      display: grid;
      place-items: center;
      .loading-animate {
        width: fit-content;
        animation: move_wave 500ms linear infinite;
        // animation-name: spin;
        // animation-duration: 500ms;
        // animation-iteration-count: infinite;
        // animation-timing-function: linear;
      }
    }
    .score-board-title {
      color: $component-text;
      h2 {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        // justify-content: flex;
        gap: 1em;
      }
    }
    .score-board-controls {
      display: grid;
      place-items: center;
      .text-content {
        color: $text;
        padding: 0.5em;
        margin: 1em 0;
        background: $primary;
        border-radius: $border-radius;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 1.3rem;
      }
    }

    .meta-mask-button {
      background: $secondary;
      font-weight: bold;
      padding: 0.5em 1em;
      width: fit-content;
      color: $highlight;
      border-radius: $border-radius;
      text-transform: uppercase;
    }
    .connect-meta-mask-container {
    }

    .score-board-total {
      .score-stats {
        display: flex;
        flex-flow: row wrap;

        gap: 0.5em;

        .stat {
          flex: 1;
          min-width: 150px;
          display: flex;
          flex-flow: column wrap;
          justify-content: space-evenly;
          align-items: stretch;
          gap: 0.2em;
          background: $primary;
          padding: 0.4em 1em;
          color: $text;
          font-weight: bolder;
          border-radius: $border-radius;

          &:nth-child(even) {
            background: $secondary;
          }
          .stat-title {
            text-transform: uppercase;
            font-weight: bolder;
            margin: 0;
          }
          .stat-value {
            margin: 0;
          }
        }
      }
      .stats-dashboard {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(375px, 1fr));
        gap: 1em;

        @include mobile-screen {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
        .image-container {
          background-color: $secondary;
          margin: 0 auto;
          display: grid;
          place-items: center;
          border-radius: $border-radius;
          img {
            width: 100%;
            // height: 100%;
            max-height: 375px;
            max-width: fit-content;
            border-radius: $border-radius;
          }
        }
        .score-board-table {
          .score-board-header {
            color: $primary;

            &:first-child {
              text-align: left;
            }
            tr {
              th {
                text-align: right;
                &:first-child {
                  text-align: left;
                }
              }
            }
          }
          .score-board-body {
            color: $secondary;
            font-weight: bolder;
            .body-cell {
              padding: 0.35em;
              // width: fit-content;
              text-align: right;
              font-weight: bolder;
              &:first-child {
                text-align: left;
              }
            }
            .project-name {
              text-transform: uppercase;
            }
            .project-score {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
