@import "~antd/dist/antd.css";
@import "./styles/variables";
@import "./styles/utils";
@import "./main.scss";
.App {
  .project-grid {
    width: 90%;
    max-width: $max-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1em;
    padding: 1em;
    .project-card {
      transition: all 500ms ease-in-out;
      background: linear-gradient(to top, $secondary, $primary, $highlight);
      box-shadow: 3px 3px 1px 0px $primary;
      color: $text;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: $border-radius;
      &:hover {
        color: $highlight;
        box-shadow: 3px 3px 1px 0px $highlight;
      }

      .project-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
      }
      .img-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 1em;

        .img {
          border-radius: $border-radius;
          flex: 1;
          max-width: 100%;
          aspect-ratio: 1 /1;
        }
      }

      &.disabled {
        opacity: 0.8;
        cursor: not-allowed;
        background: lightgrey;
        box-shadow: none;
        mix-blend-mode: luminosity;
        &:hover {
          box-shadow: none;
          color: $text;
        }
      }
    }
  }
}
