@import "../styles/utils";
@import "../styles/variables";

.project-page {
  .title {
    color: $highlight;
    font-weight: bolder;
    text-transform: uppercase;
  }
}

.page-content {
  border-radius: $border-radius;
  box-shadow: $project-box-shadow;
  .ant-card-body {
    padding: 0;
    margin: 0;
  }
}
