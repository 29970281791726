@import "../../styles/variables";
@import "../../styles/utils";

.sub-header-container {
  grid-area: sub-header;
  background: $secondary;
  display: flex;

  flex-flow: column nowrap;
  text-transform: uppercase;

  height: 100%;

  &.get-paid {
    .upgrade-container {
      width: 100%;
      background: $highlight;

      .upgrade-button {
        cursor: pointer;
        margin: 0 auto;
        justify-self: center;
        width: fit-content;
        padding: 0.25em 1em;
        font-size: 1.3em;
        border-radius: $border-radius;
        color: $primary;
        &:hover,
        &:focus {
          color: lighten($primary, 10%);
          
        }
      }
    }
  }
  .info-container {
    display: flex;
    gap: 1em;
    padding: 0.25em;
    flex-flow: row nowrap;
    align-items: center;
    .screen-grab-button {
      background: $primary;
      font-size: 0.8em;
      padding: 0.25em;
      width: fit-content;
      font-weight: bolder;
      border-radius: $border-radius;
      transition: all ease-in 100ms;
      color: $text;
      position: relative;
      cursor: pointer;
      margin: 0;
      height: 100%;
      &:hover,
      &:active,
      &:focus {
        background: darken($primary, 10%);
      }
      .meta-mask-logo {
        width: 20px;
        background: none;
        margin-left: 1em;
      }
    }
    .project-button {
      color: $text;
      position: relative;
      background: $primary;
      width: fit-content;
      padding: 0.25em;
      font-size: 0.8em;
      border-radius: $border-radius;
      display: grid;
      align-items: center;
      &:hover,
      &:focus {
        background: lighten($primary, 5%);
      }
    }

    @include mobile-screen {
      font-size: 0.8em;
    }
  }
}
