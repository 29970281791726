@import "../../styles/variables";
@import "../../styles/utils";

.footer-container {
  z-index: 10;
  grid-area: footer;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(fit-content, 1fr));
  grid-template-areas: "site-map call-outs legal";
  // justify-content: space-between;
  align-items: stretch;
  width: 100%;
  background: #f0f2f5;
  padding: 20px;
  border-top: 2px solid darken($yellow, 10%);
  height: 150px;
  @include mobile-screen {
    grid-template-areas:
      "call-outs  call-outs"
      "site-map legal";
  }
  .site-map {
    grid-area: site-map;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    a {
      color: darken($purple, 20%);
      &:hover {
        color: $primary;
      }
    }
  }
  .call-outs {
    display: grid;
    place-items: center;
    grid-area: call-outs;
    font-size: 1.1rem;
    text-align: center;

    color: inherit;
    :hover {
      color: $primary;
    }
  }
  .legal {
    grid-area: legal;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    color: darken($purple, 20%);
    @include mobile-screen {
      font-size: 0.8rem;
      text-align: right;
    }
  }
}
