@import "../../styles/variables";
@import "../../styles/utils";
.about-layout {
  .about-content {
    background: $component-background;
    color: darken($component-text, 30%);
    margin: 15px auto;
    padding: 10px;
    border-radius: $border-radius;
    box-shadow: $component-box-shadow;
    width: 100%;
    max-width: $max-content;
    .title {
      text-align: center;
      color: $component-text;
      font-size: 1.7rem;
    }

    section {
      margin: 0;
      margin-bottom: 15px;
      padding: 10px;
      div {
        margin-top: 10px;
        &.standout {
          font-size: 1.15rem;
          font-weight: 500;
        }
        a {
          padding: 0 3px;
        }
      }
    }
  }
  @include mobile-screen {
    width: 90%;
    margin: 0 auto;
    .about-content {
      .title {
        font-size: 1.2rem;
      }
      section {
        margin: 0;
        margin-bottom: 2em;
        padding: 1em;
        div {
          margin-top: 1em;
          &.standout {
            font-size: 1rem;
            font-weight: 300;
          }
          a {
            padding: 0 1em;
          }
        }
      }
    }
  }
}
