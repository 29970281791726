@import "../../styles/variables";
@import "../../styles/utils";

.sale-button {
  width: 100%;
  max-width: fit-content;
  padding: 0.7em 1em;
  background: $primary;
  color: $text;
  border-radius: $border-radius;
  transition: all 300ms;
  border: 3px solid $secondary;
  font-size: 2rem;
  disabled: "disabled";
  margin: 1em;

  &:hover {
    cursor: pointer;
    background: darken($primary, 10%);
    border: 3px solid lighten($secondary, 5%);
  }
}

.close-button {
  width: 25%;
  max-width: fit-content;
  padding: 0.15em 1em;
  background: $primary;
  color: $text;
  border-radius: $border-radius;
  transition: all 300ms;
  border: 3px solid $secondary;
  font-size: 1rem;
  disabled: "disabled";
  margin: 1em;

  &:hover {
    cursor: pointer;
    background: darken($primary, 10%);
    border: 3px solid lighten($secondary, 5%);
  }
}

.header-modal {
  color: lime;
}

.flex-img-button {
  color: $secondary;
  &:hover {
    color: $primary;
  }
}

.flex-address {
  text-align: center;
  padding: 0.5em;
  font-size: 1rem;
  @include mobile-screen {
    width: 100%;
    font-size: 0.65rem;
    line-break: anywhere;
  }
}

.about-layout {
  width: 90%;
  max-width: $max-content;
  margin: 15px auto;
  .about-content {
    box-shadow: $component-box-shadow;
    background: $component-background;
    color: darken($component-text, 30%);
    padding: 10px;
    border-radius: 6px;

    .title {
      text-align: center;
      color: $component-text;
      font-size: 1.7rem;
    }
    .standout {
      // background: $primary;
      // border: 3px solid $secondary;
      // border-radius: $border-radius;

      // color: $text;
      display: grid;
      place-items: center;

      max-width: 75ch;
      margin: 0 auto;
      padding-bottom: 2em;
      .sale-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        margin: 0;
        .price {
          padding: 0.5em;
          font-size: 2em;
          margin: 0;
          color: $highlight;
          background: $secondary;
          border-radius: $border-radius;
        }
        .owner {
          display: flex;
          flex-flow: column nowrap;
          font-size: 0.85em;
          :first-child {
            background: $primary;
            max-width: fit-content;
            padding: 0.2em 0.5em;
            border-radius: $border-radius;
            color: $highlight;
            font-weight: bold;
            font-size: 1.5em;
          }
          // color: $highlight;
          // background: $secondary;
          padding: 0.25em;
          border-radius: $border-radius;
          margin-left: 0.2em;
        }
      }
      .the-punk {
        background: $punk-bg;
      }
      .description {
        max-width: 55ch;
        margin: 0.2em auto;
        .desc-title {
          text-transform: uppercase;
          font-size: 1.2em;
          color: $highlight;
          text-decoration: underline;
        }
        b {
          color: $primary;
        }
      }
    }
    .exp {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: 1em;
      * {
        margin: 0;
      }
      .list {
        flex: 1;
        border: 3px solid $secondary;
        border-radius: $border-radius;
        padding: 1em;
        .t {
          font-size: 1.1rem;
          font-weight: bolder;
        }
        .options {
          display: flex;
          flex-direction: column;
          gap: 0.2em;
          margin-left: 0.5em;
        }
      }
    }
  }
  @include small-screen {
    .sale-header {
      flex-direction: column;
      gap: 1em;
      .price {
        border-radius: $border-radius;
      }
    }
    .exp {
      display: flex;
      flex-flow: column nowrap;
    }
  }
  @include mobile-screen {
    width: 100%;
    padding: 0;

    .about-content {
      padding: 0;
      .title {
        font-size: 1rem;
      }
      section {
        margin: 0;
        font-size: 0.9rem;
        div {
          &.standout {
            font-size: 0.9rem;
            font-weight: 300;
          }
          a {
            padding: 0;
          }
        }
      }
      .exp {
        display: flex;
        flex-flow: column nowrap;
      }
      .sale-button {
        margin-bottom: 0;
      }
    }
  }
}

.react-pdf__Page {
  margin-top: 100px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  height: 800px;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 900px;
  overflow: auto;
}
