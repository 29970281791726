@import "../../styles/variables";
.iframe-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .iframe-item {
    width: 100%;
    // min-height: 100vh;
    .iframe-item-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: $border-radius;
      background: transparent;
    }
  }
}
