@import "~antd/dist/antd.css";

html {
  background-color: #121c1f;
  --pink: #ff48c4;
  --red: #ff3f3f;
  --blue: #2bd1fc;
  --teal: #2ef9ff;
  --yellow: #f3ea5f;
  --purple: #c04df9;
  --orange: #ffc761;
  --white: #ffffff;
  --grey: #807b7b;
  --black: #000000;
  --green: #5fff47;
  --lime: #b3f26b;

  --primary: var(--pink);
  --secondary: var(--blue);
  --highlight: var(--yellow);

  --component-background: var(--yellow);
  --component-text: var(--purple);
  --component-box-shadow: var(--primary) 0 0 2px 3px;
  --project-box-shadow: var(--highlight) 0 0 2px 3px;

  --text: var(--white);
  --border-radius: 7px;
  --max-content: 1200px;
}
::selection {
  background-color: var(--purple);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121c1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* $primary:  #ff48c4; */
/* $secondary:  #2bd1fc; */
/* $yellow:  #f3ea5f; */
/* $purple:  #c04df9; */
/* $red:  #ff3f3f; */
