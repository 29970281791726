@import "../../styles/utils";
@import "../../styles/variables";

.mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  .panel {
    padding: 0.35rem;
    margin: 0.35rem;
    width: fit-content;
    border-radius: 7px;
    background: $yellow;

    border: 2px solid $primary;
    .title {
      color: $red;
      text-transform: uppercase;
      text-align: center;
      font-weight: 900;
    }
    .content {
      color: $purple;
      font-weight: 700;
      margin: 0 0.5rem;
      text-align: center;
    }
  }
}
