@mixin large-screen {
  @media (min-width: 1000px) {
    @content;
  }
}
@mixin medium-screen {
  @media (max-width: 1000px) {
    @content;
  }
}
@mixin small-screen {
  @media (max-width: 800px) {
    @content;
  }
}
@mixin mobile-screen {
  @media only screen and (max-width: 600px) {
    @content;
  }
}
