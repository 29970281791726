@import "../../styles/variables";
@import "../../styles/utils";
.default-layout {
  display: grid;
  grid-template-areas:
    "header header"
    "sub-header sub-header"
    "content content"
    "footer footer";
  grid-template-rows: 60px auto minmax(100vh, 1fr) auto;
  // grid-template-columns: 110px 1fr;
  // gap: 1em;
  &.no-left-nav {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "header header"
      "sub-header sub-header"
      "content content"
      "footer footer";
  }
  background: linear-gradient(
    to bottom,
    $secondary,
    lighten($secondary, 10%),
    darken($secondary, 10%)
  );
  background-image: url("../../assets/imgs/bg-flare.PNG");
  background-position: top;
  background-size: cover;

  .content {
    grid-area: content;
  }
  @include mobile-screen {
    grid-template-rows: 40px auto minmax(100vh, 1fr) auto;
    background-image: url("../../assets/imgs/bg-flare.PNG");
    background-position: top;
    background-size: cover;
  }
}
