@import "../../styles/utils";
@import "../../styles/variables";

.left-nav-container {
  grid-area: leftNav;
  // min-height: 100vh;
  background-color: transparent;
  position: absolute;
  top: 65px;
  right: 0;
  text-align: left;
  width: 100%;
  max-width: 600px;
  overflow-y: scroll;
  z-index: 10;

  @include mobile-screen {
    top: 45px;
    right: 0;
    left: 0;
    padding: 1em;
  }
  .list-modal {
    position: relative;
    top: 0;
    right: 0;

    .list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      background: $primary;
      align-items: stretch;
      gap: 0.5em;
      border-radius: $border-radius;
      padding: 0.5em;

      .list-item {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        transition: all 300ms ease-in-out;
        color: $text;
        background: linear-gradient(to top, $secondary, $primary, $highlight);
        box-shadow: 3px 3px 1px 0px $primary;
        word-wrap: break-word;
        border-radius: $border-radius;
        padding: 0;

        .img-container {
          flex: 1;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;

          .img {
            flex: 1;
            border-radius: $border-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            max-width: 100%;
            // height: 75px;
            aspect-ratio: 1 /1;
            mix-blend-mode: hard-light;
          }
        }
        .title {
          flex: 1;
          display: grid;
          place-items: center;
          padding: 0.1em;
          font-size: 0.8em;
        }
        @media (max-width: 800px) {
          padding: 0;
          .title {
            flex: 1;
            display: block;
            padding: 0.2em 1em;
            font-size: 0.8em;
          }
        }
        &:hover,
        &:focus {
          color: darken($highlight, 10%);
          box-shadow: 3px 3px 1px 0px $highlight;
        }

        &.degen-link-active {
          box-shadow: 3px 3px 1px 0px $highlight;
        }
        &.disabled {
          opacity: 0.8;
          cursor: not-allowed;
          background: lightgrey;
          box-shadow: none;
          mix-blend-mode: luminosity;
          &:hover {
            box-shadow: none;
            color: $text;
          }
        }
      }
    }
  }
}
